import React,{useEffect,useState,useRef} from "react";

const  Header = () => {
        
    const headerRef = useRef(null)
    const stickyHeaderFun = ()=>{
        window.addEventListener( 'scroll',()=>{
            if(document.body.scrollTop>100 || document.documentElement.scrollTop>100){
                headerRef.current.classList.add('sticky_header')
            }else{
                headerRef.current.classList.remove('sticky_header') 
            }
        })
            
    }
    useEffect(()=>{
        stickyHeaderFun()
        return window.removeEventListener('scroll',stickyHeaderFun)
    },[])
    return (
        <header ref={headerRef}             
                className="w-full h-[80px] leading-[100px] flex items-center md:block sm:block lg:block"
        >
            <div className="container">
                <div className="flex items-center justify-between">
                    {/* ======== logo start =======*/}
                    <div className="flex items-center gap-[10px]">
                        <span className="w-[35px] h-[35px] bg-primaryColor text-white text-[18px] font[500] rounded-full flex items-center justify-center">
                            <a href="#">A</a>
                        </span>
                        <div className="leading-[20px]">
                            <h2 className="text-xl text-smallTextColor font-[700]">
                                <a href="#">Abdelnour</a>
                            </h2>
                            <p className="text-[18px] text-smallTextColor font-[500]">Personal</p>
                        </div>
                    </div>
                    {/* ======= logo end ========== */}
                    {/* ======= menu start ========== */}
                    <div className="menu hidden sm:block">
                        <ul className="flex items-center gap-10">
                            <li>
                                <a className = "text-smallTextColor font-[600]" href="#about">About</a>
                            </li>
                            <li>
                                <a className = "text-smallTextColor font-[600]" href="#experience">Experience</a>
                            </li>
                            <li>
                                <a className = "text-smallTextColor font-[600]" href="#education">Education</a>
                            </li>
                            <li>
                                <a className = "text-smallTextColor font-[600]" href="#skills">Skills</a>
                            </li>
                            <li>
                                <a className = "text-smallTextColor font-[600]" href="#projects">Projects</a>
                            </li>
                            
                            <li>
                                <a className = "text-smallTextColor font-[600]" href="#contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                    {/* ======= menu end ========== */}
                    {/* ======= menu right start ========== */}
                    <div className="flex items-center gap-10">
                        <div className="hidden sm:block">
                            <button className="flex items-center gap-1 text-smallTextColor font-[600] border border-solid border-smallTextColor py-2 px-4 rounded-[8px] max-h-[40px] hover:bg-smallTextColor hover:text-white hover:font-[500] ease-in duration-500">
                                <a href="#contact">
                                    <i class="ri-send-plane-line pr-[8px]"></i>Contact Me
                                </a>
                            </button>
                        </div>
                        
                        
                    </div>
                    <span className="text-2xl text-smallTextColor sm:hidden cursor-pointer">
                            <i class="ri-menu-line"></i>
                    </span>
                    {/* ======= menu right end ========== */}
                </div>
            </div>
            
        </header>
    )
}
export default Header;