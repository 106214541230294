import React,{useState,useEffect} from "react";
import skillData from "../../assets/data/skillData";
import SkillsList from  '../ItemsList/SkillsList';
import ScrollCarousel from 'scroll-carousel-react';
import IconData from "../../assets/data/iconData";
import "/home/ahmed/Desktop/web projects/Portfolios/portfolio/src/styles/programLan.css"

function Skills() {
    
	
    return (
        <section id="skills">
            <div className="container">
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="sm:mb-0 w-[50%]">
                            <h3 className="text-headingColor font-[700] text-[2rem] mb-2">
                                Skills & Expertise
                            </h3>
                        </div>
                        <div className="rounded-[8px] text-start sm:block bg-headingColor w-full h-2 mt-3"></div>
                    </div>
                    <div className="flex flex-col justify-center sm:py-12">
                        <div className="w-full py-5 px-2 sm:max-w sm:mx-auto sm:px-0">
                            <div className="relative text-gray-700 antialiased text-sm font-semibold">
                                
                                {/*========== Start left Card1 ============= */}
                                <div className="mt-2 sm:mt-0 sm:mb-12">
                                    <div className="flex items-center flex-col sm:flex-row">
                                        {/* ============ */}
                                        <div className="flex justify-start w-full mx-auto items-center">
                                            <div className="w-full sm:w-[100%] sm:pr-7">
                                                <div 
                                                    className="bg-white bg-opacity-40 p-4 group w-full">
                                                    <h5 className="text-headingColor font-[500] mb-3 text-[25px] leading-[40px] text-opacity-80">
                                                        Skilled in Data Science, Machine Learning, and Deep Learning, my expertise in Artificial Intelligence drives innovative solutions. Proficient in Web Development, I create dynamic websites, complemented by strong Database Management skills. Leveraging Data Analytics and advanced statistical techniques, I extract actionable insights for informed decision-making. These skills converge to empower me to tackle complex challenges and deliver exceptional results.
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End of the left section */}
                                <div className="flex flex-row gap-8 flex-wrap text-center justify-between">
                                    {skillData.map((skill,index)=>(
                                        <div key={index} className="relative w-full sm:max-w-[350px] rounded-[20px] shadow-lg text-white overflow-hidden sm:h-[800px] sm:max-h-[500px] h-[540px]">
                                        {/* Background overlay with filter and blur effect */}
                                        <div
                                            className="absolute inset-0 rounded-[20px] filter blur-[3px]"
                                            style={{ backgroundImage: `url(${skill.imgUrl})` }}
                                        ></div>
                                        {/* Content */}
                                        <div className="relative z-10 p-4"
                                            //  style={{ backgroundImage: `url(${skill.imgUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                             >
                                            <h3 className="py-7 leading-7 text-[28px]" data-aos="fade-right" data-aos-duration="900">
                                                {skill.title}
                                            </h3>
                                            <h5 className="px-5 pb-5 text-[20px] text-gray-300 leading-7" data-aos="fade-left" data-aos-duration="900">
                                                {skill.description}
                                            </h5>
                                            <p className="text-[18px] flex items-start pl-4 pb-7">
                                                    {/* WITH : */}
                                            </p>
                                            <div className="icons-container flex flex-row">
                                                {skill.icon.map((iconUrl, index) => (
                                                    <figure className="w-[50px] h-[50px] border-headingColor bg-transparent justify-between mx-4 pb-4" key={index}>
                                                        <img src={iconUrl} alt={`icon-${index}`} className="shadow-transparent items-center"/>
                                                    </figure>
                                                ))}
                                            </div>
                                        </div>
                                    </div>                                    
                                    ))}
                                </div>
                                <div className="bg-gray-200 pt-[20px] mt-[50px] rounded-[19px]">
                                    {/* <div className="text-headingColor font-[500] text-[50px] m-10 text-center">
                                        <h3>Technologies</h3>
                                    </div> */}
                                    <div className="slider">
                                        <div className="slide-track">
                                                {IconData.map((icon,index)=>(
                                                    <div key={index} className="slide m-[10px]">
                                                        <figure className="w-[100px] h-[100px]">
                                                            <img src={icon.img} alt={icon.alt} className="px-4 shadow-transparent"/>
                                                        </figure>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
            </div>
            
        </section>
    );
}
export default Skills;