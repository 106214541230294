import React from "react";
// import heroImg from  '../../assets/images/photoProfile-removebg.png';
import heroImg from  '../../assets/images/photoProfile.jpeg';
import CountUp from 'react-countup';


const Hero = () =>{
    return(
        <section className="pt-0" id="about">
            <div className="container pt-[20px]">
                <div className="md:flex items-center justify-between sm:flex-col md:flex-row">
                    {/* ======= left side  start ==========*/}
                    <div className="w-full md:basis-1/2">
                        {/* <h5 
                            data-aos = "fade-right"
                            data-aos-duration = "2000"
                            className="text-headingColor font-[600] text-[16px]"
                        >
                            Hello Welcome
                        </h5> */}
                        <h1
                            data-aos = "fade-up"
                            data-aos-duration = "2000"
                            className="text-headingColor font-[800] text-[1.8rem] sm:text-[40px] leading-[34px] sm:leading-[48px] sm:mt-[100px]"
                        >
                            Hey , I'm Abdelnour<br/>Data Science & AI
                            <br/>  student,Web Developper
                        </h1>
                        
                        <p
                                data-aos = "fade-left"
                                data-aos-duration = "1800"
                                className="flex gap-2 text-headingColor font-[500] text-[18px] leading-7  sm:pr-10 mt-[30px]"
                            >
                                I am Abdelnour, currently a student of Industrial Engineering, specialized in Data Science and Artificial Intelligence at the National Polytechnic School of Algiers. I am a student with a passion for learning, creating and solving problems.
                        </p>
                        <div
                            data-aos = "fade-up"
                            data-aos-duration = "2000"
                            data-aos-delay = "200"
                            className="flex items-center gap-6 mt-[40px]"
                        >
                            <a href="#contact">
                                <button className="bg-primaryColor text-white font-[500] flex items-center gap-2 hover:bg-smallTextColor ease-in duration-300 py-2 px-4 rounded-[8px]">
                                    <i class="ri-mail-line"></i>Hire me 
                                </button>
                            </a>
                            <a 
                                href="#projects" 
                                className="text-smallTextColor font-[600] text-[16px] border-b border-solid border-smallTextColor"
                            
                            >See Projects</a>
                            
                        </div>
                        <div className="flex items-center gap-9 mt-14">
                            {/* <span className="text-smallTextColor text-[15px] font-[600]">
                                Follow Me:
                            </span> */}
                            {/* <span>
                                <a  href="#" 
                                    className="text-smallTextColor text-[18px] font-[600]"
                                >
                                    <i class="ri-linkedin-box-fill"></i>
                                </a>
                            </span> */}
                            
                            <span className="w-[35px] h-[35px] bg-gray-900 hover:bg-primaryColor p-1 rounded-[50px] cursor-pointer text-center">
                                <a  target="_blank"
                                    className="text-white font-[500] text-[18px]"
                                    href="https://www.linkedin.com/in/ahmed-abdennour-terfani-1b22a7251/"
                                >
                                    <i class="ri-linkedin-fill"></i>  
                                </a>
                            </span>
                            <span className="w-[35px] h-[35px] bg-gray-900 hover:bg-primaryColor p-1 rounded-[50px] cursor-pointer text-center">
                                <a  className="text-white font-[500] text-[18px]"
                                    href="https://github.com/terfaniahmedabdelnour"
                                    target="_blank"
                                >
                                    <i class="ri-github-line"></i>  
                                </a>
                            </span>
                            <span className="w-[35px] h-[35px] bg-gray-900 hover:bg-primaryColor p-1 rounded-[50px] cursor-pointer text-center">
                                <a  className="text-white font-[500] text-[18px]"
                                    href="https://discordapp.com/users/abdennour#2938"
                                    target="_blank"
                                >
                                    <i class="ri-discord-fill"></i> 
                                </a>
                            </span>
                        </div>
                    </div> 
                    {/* ======= left side end ==========*/}
                    {/* ======= hero img start ==========*/}
                    <div className="basis-1/3 mt-10 sm:mt-10">
                        <figure className="flex items-center justify-center">
                            <img className = "rounded-l-[80px] rounded-t-[50px]"src={heroImg} alt=""/>
                        </figure>
                    </div>
                    {/* ======= hero img end ==========*/}
                    {/* ======= hero content right start ==========*/}
                    <div className="md:basis-1/5 flex justify-between text-center mt-10 flex-wrap gap-3 md:mt-0 md:flex-col md:justify-end md:text-end">
                        <div className="mb-10">
                            <h2 className="text-headingColor font-[700] text-[32px]">
                            <CountUp start={0} end={1} duration={2} suffix="+"/>
                            </h2>
                            <h4 className="text-headingColor text-[18px] font-[600]">
                                Year of Experience
                            </h4>
                        </div>
                        <div className="mb-10">
                            <h2 className="text-headingColor font-[700] text-[32px]">
                            <CountUp start={0} end={100} duration={2} suffix="%"/>
                            </h2>
                            <h4 className="text-headingColor text-[18px] font-[600]">
                                Success rate 
                            </h4>
                        </div>
                        {/* <div className="mb-10">
                            <h2 className="text-headingColor font-[700] text-[32px]">
                            <CountUp start={0} end={150} duration={2} suffix="+"/>
                            </h2>
                            <h4 className="text-headingColor text-[18px] font-[600]">
                                Happy Clients
                            </h4>
                        </div> */}
                        <div className="mb-10">
                            <h2 className="text-headingColor font-[700] text-[32px]">
                            <CountUp start={0} end={10} duration={2} suffix="+"/>
                            </h2>
                            <h4 className="text-headingColor text-[18px] font-[600]">
                                Project Completed
                            </h4>
                        </div>

                    </div>
                    {/* ======= hero content right end ==========*/}

                </div>
            </div>
        </section>
    )
}
export default Hero ;