import React from "react";
import frontendImg from  '../../assets/images/front-end.png';
import backendImg from  '../../assets/images/backend.png';
import uiImg from  '../../assets/images/design.png';
import appsImg from  '../../assets/images/apps.png';
import enpLogo from '../../assets/images/Logo-ENP.webp';
import gomycodeLogo from '../../assets/images/gomycode.webp';
import errochd from '../../assets/images/ecoleErrochd.jpg';
import educationData from '../../assets/data/educationData';
const Education = () =>{
    return(
        <section id="education">
            <div className="container lg:pt-5">
                <div className="text-start">
                    <h2 className="text-headingColor font-[700] text-[2.4rem]">
                        Education
                    </h2>
                    <div className="rounded-[8px] text-start sm:block bg-headingColor w-full h-2 left-1/2 transform-translate-y-1/2"></div>
                </div>
                <div className="flex flex-col justify-center sm:py-12">
                    <div className="w-full py-5 px-2 sm:max-w sm:mx-auto sm:px-0">
                        <div className="relative text-gray-700 antialiased text-sm font-semibold">
                            {/* ======== Start Vertical line running through the middle =========*/}
                            <div className="hidden absolute w-1 sm:block bg-smallTextColor h-full left-1/2 transform-translate-x-1/2"></div>
                            {/* ======== End Vertical line running through the middle =========*/}
                            {/*========== Start left Card1 ============= */}
                            <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">
                                    {/* ============ */}
                                    <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                                <div data-aos = "fade-right" 
                                                    data-aos-duration='900' 
                                                    className="bg-white bg-opacity-40 rounded shadow-2xl group cursor-pointer ease-in duration-150 w-full p-6 mb-10">
                                                    <h3 className="text-primaryColor font-[700] mb-3 leading-9 text-[35px]  pt-5">
                                                        Master's Degree in Data Science & Artifical Itelligence Degree
                                                    </h3>
                                                    <div className="flex justify-between">
                                                        <div>
                                                            <h4 className="text-headingColor font-[700] mb-3 text-[25px]">
                                                                Algiers,Algeria
                                                            </h4>
                                                            <h5 className="text-headingColor font-[500] mb-3 text-xl">
                                                                2022-2025
                                                            </h5>
                                                            <p className="text-[20px] text-smallTextColor leading-7">
                                                                National Polytechnic School
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <figure>
                                                                <img src={enpLogo} alt="ENP" className="sm:w-[100px] mt-5"/>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    {/* ========= */}
                                    <div className="rounded-full bg-primaryColor border-white border-4 w-[60px] h-[60px] absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="border-black">
                                            <img src={frontendImg} alt=""/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            {/* ========== end left Card1 =========== */}
                            {/* ========== Start Right Card1 ========= */}
                            <div className="mt-6 sm:mt-0 sm:mb-0">
                                <div className="flex items-center flex-col sm:flex-row">
                                    {/* ============ */}
                                    <div className="flex justify-end w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pl-8">
                                            <div 
                                                data-aos =  "fade-left" 
                                                data-aos-duration='900' 
                                                className="bg-white bg-opacity-40 rounded shadow-2xl group cursor-pointer ease-in duration-150 w-full p-6">
                                                <h3 className="text-primaryColor font-[700] mb-3 leading-9 text-[35px]  pt-5">
                                                    Data Science Bootcamp
                                                </h3>
                                                <div className="flex justify-between">
                                                    <div>
                                                        <h4 className="text-headingColor font-[700] mb-3 text-[25px]">
                                                            Algiers,Algeria
                                                        </h4>
                                                        <h5 className="text-headingColor font-[500] mb-3 text-xl">
                                                            2023-2024
                                                        </h5>
                                                        <p className="text-[20px] text-smallTextColor leading-7">
                                                            GOMYCODE
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <figure>
                                                            <img src={gomycodeLogo} alt="GOMYCODE" className="sm:w-[200px] mt-5"/>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ========= */}
                                    <div className="rounded-full bg-primaryColor border-white border-4 w-[60px] h-[60px] absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure>
                                            <img src={backendImg} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            
                            </div>
                            {/*  =========== end Right Card1 =========== */}
                            {/*========== Start left Card2 ============= */}
                            <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">
                                    {/* ============ */}
                                    <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                                <div data-aos = "fade-right" 
                                                    data-aos-duration='900' 
                                                    className="bg-white bg-opacity-40 rounded shadow-2xl group cursor-pointer ease-in duration-150 w-full p-6 mb-10">
                                                    <h3 className="text-primaryColor font-[700] mb-3 leading-9 text-[35px]  pt-5">
                                                        Introduction to Cyber Security
                                                    </h3>
                                                    <div className="flex justify-between">
                                                        <div>
                                                            <h4 className="text-headingColor font-[700] mb-3 text-[25px]">
                                                                Algiers,Algeria
                                                            </h4>
                                                            <h5 className="text-headingColor font-[500] mb-3 text-xl">
                                                                2023-2024
                                                            </h5>
                                                            <p className="text-[20px] text-smallTextColor leading-7">
                                                                GOMYCODE
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <figure>
                                                                <img src={gomycodeLogo} alt="GOMYCODE" className="sm:w-[200px] mt-5"/>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    {/* ========= */}
                                    <div className="rounded-full bg-primaryColor border-white border-4 w-[60px] h-[60px] absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="border-black">
                                            <img src={frontendImg} alt=""/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            {/*  =========== end Left Card2 =========== */}
                            {/* ========== Start Right Card2 ========= */}
                            <div className="mt-6 sm:mt-0 sm:mb-0">
                                <div className="flex items-center flex-col sm:flex-row">
                                    {/* ============ */}
                                    <div className="flex justify-end w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pl-8">
                                            <div 
                                                data-aos =  "fade-left" 
                                                data-aos-duration='900' 
                                                className="bg-white bg-opacity-40 rounded shadow-2xl group cursor-pointer ease-in duration-150 w-full p-6">
                                                <h3 className="text-primaryColor font-[700] mb-3 leading-9 text-[35px]  pt-5">
                                                    Introduction to Web Development
                                                </h3>
                                                <div className="flex justify-between">
                                                    <div>
                                                        <h4 className="text-headingColor font-[700] mb-3 text-[25px]">
                                                            Algiers,Algeria
                                                        </h4>
                                                        <h5 className="text-headingColor font-[500] mb-3 text-xl">
                                                            2022-2023
                                                        </h5>
                                                        <p className="text-[20px] text-smallTextColor leading-7">
                                                            Ecole Erochd
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <figure>
                                                            <img src={errochd} alt="ECOLE ERROCHD" className="sm:w-[100px] mt-5"/>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ========= */}
                                    <div className="rounded-full bg-primaryColor border-white border-4 w-[60px] h-[60px] absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure>
                                            <img src={backendImg} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            
                            </div>
                            {/*  =========== end Right Card2 =========== */}
                            {/*========== Start left Card3 ============= */}
                            <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row">
                                    {/* ============ */}
                                    <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                                <div data-aos = "fade-right" 
                                                    data-aos-duration='900' 
                                                    className="bg-white bg-opacity-40 rounded shadow-2xl group cursor-pointer ease-in duration-150 w-full p-6 mb-10">
                                                    <h3 className="text-primaryColor font-[700] mb-3 leading-9 text-[35px]  pt-5">
                                                        Preparatory Classes
                                                    </h3>
                                                    <div className="flex justify-between">
                                                        <div>
                                                            <h4 className="text-headingColor font-[700] mb-3 text-[25px]">
                                                                Algiers,Algeria
                                                            </h4>
                                                            <h5 className="text-headingColor font-[500] mb-3 text-xl">
                                                                2020-2022
                                                            </h5>
                                                            <p className="text-[20px] text-smallTextColor leading-7">
                                                                National Polytechnic School
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <figure>
                                                                <img src={enpLogo} alt="ENP" className="sm:w-[100px] mt-5"/>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    {/* ========= */}
                                    <div className="rounded-full bg-primaryColor border-white border-4 w-[60px] h-[60px] absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure className="border-black">
                                            <img src={frontendImg} alt=""/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            {/*========== end left Card3 ============= */}
                            {/*========== Start left Card2 ============= */}
                            {/* <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row"> */}
                                    {/* ============ */}
                                    {/* <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                            <div data-aos =  "fade-right" 
                                                // data-aos-delay='100'
                                                data-aos-duration='900' 
                                                className="bg-white p-4 rounded shadow-lg group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                                <h3 className="text-primaryColor font [700] mb-3 text-xl">
                                                    UI/UX Design
                                                </h3>
                                            <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic perferendis dolores omnis, quisquam excepturi atque nam ex aliquam modi eaque ipsa quo.
                                            </p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* ========= */}
                                    {/* <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure>
                                            <img src={uiImg} alt="UI/UX Design" />
                                        </figure>
                                    </div>
                                </div>
                            </div> */}
                            {/* ========== end left Card2 =========== */}
                            {/* ========== Right Card2 ========= */}
                            {/* <div className="mt-6 sm:mt-0 sm:mb-12">
                                <div className="flex items-center flex-col sm:flex-row"> */}
                                    {/* ============ */}
                                    {/* <div className="flex justify-end w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pl-8">
                                            <div data-aos =  "fade-left" 
                                                data-aos-duration='900' 
                                                className="bg-white p-4 rounded shadow-lg group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                                <h3 className="text-primaryColor font [700] mb-3 text-xl">
                                                    Apps Development
                                                </h3>
                                            <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic perferendis dolores omnis, quisquam excepturi atque nam ex aliquam modi eaque ipsa quo.
                                            </p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* ========= */}
                                    {/* <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                                        <figure>
                                            <img src={appsImg} alt="Back-end Developement" />
                                        </figure>
                                    </div>
                                </div>
                            </div> */}
                            {/*  =========== end Right Card2 =========== */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default  Education; 