import React from "react";
import projects from "../../assets/data/projectData";
const ProjectsList = ({activeID,setShowModal}) => {
    const  project = projects.find(project=> project.id === activeID);
    const handleClickOutsideModal = (event) => {
        if (event.target.classList.contains('modal-overlay')) {
            // Close the modal
            setShowModal(false);
        }};   
    return (
        <div className="w-full h-full fixed top-0 left-0 z-[2000] bg-headingColor bg-opacity-40"
            // onClick={handleClickOutsideModal}
        >
            <div className="max-w-[600px] absolute top-1/2 left-1/2 z-20 bg-white rounded-[8x] transform -translate-x-1/2 -translate-y-1/2 p-5">
                <div>
                    <figure>
                        <img src={project.imgUrl} alt="" className="rounded-[8px]"/>
                    </figure>
                    <div>
                        <h2 className="text-2xl text-headingColor font-[700] my-5">
                            {project.title}
                        </h2>
                        <p className="text-[15px] leading-7 text-smallTextColor">
                            {project.description}
                        </p>
                        <div className="mt-5 flex items-center gap-3 flex-wrap">
                            <h4 className="text-headingColor text-[18px] text-[700]">
                                Technologies:
                            </h4>
                            {project.technologies.map((item,index)=>(
                                <span
                                    key={index}
                                    className="bg-gray-200 py-1 px-2 rounded-[5px] text-[14px] leading-0"
                                >
                                    {item}
                                </span>
                            ))}
                        </div>

                        <a href={project.siteUrl}>
                            <button className="bg-primaryColor text-white py-2 px-4 my-8 rounded-[8px] font-[500] hover:bg-headingColor ease-in duration-300">
                                Demo
                            </button>
                            
                        </a>
                        <a href={project.githubUrl} target="_blank" rel="noreferrer">
                            <button className="bg-headingColor text-white py-2 px-4 my-8 rounded-[8px] font-[500] hover:bg-primaryColor ease-out duration-100 ml-4">
                                View Github
                            </button>
                        </a>

                    </div>
                    <button
                        onClick={()=> setShowModal(false)}
                        className="w-[25px] h-[30px] bg-white absolute top-[30px] right-[30px] text-[47px] flex items-center rounded-[3px] cusrsor-pointer"
                    >
                        &times;
                    </button>

                </div>
            </div>
        </div>
    )
}
export default  ProjectsList;