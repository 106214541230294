import { useEffect } from 'react'; 
import Aos from 'aos';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Hero from './components/Sections/Hero';
import Skills from './components/Sections/Skills';
import Experience from './components/Sections/Experience';
import Projects from './components/Sections/Projects';
import Education from  './components/Sections/Education';
import Contact from './components/Sections/Contact';
function App() {
  useEffect(()=>{
    Aos.init();
  },[]);
  return <>
    <Header />
    <main>
      <Hero />
      <Experience />
      <Education />
      <Skills />
      <Projects />
      <Contact />
    </main>
    <Footer />
    </>
        
      
}

export default App;
