import React from "react";
import frontendImg from  '../../assets/images/front-end.png';
import backendImg from  '../../assets/images/backend.png';
import uiImg from  '../../assets/images/design.png';
import appsImg from  '../../assets/images/apps.png';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import companyData from "../../assets/data/companyData";
// import ericsson from "/home/ahmed/Desktop/web projects/Portfolios/portfolio/src/assets/images/companies/ericsson.webp";
const Experience = () =>{
    return(
        <section id="experience">
            <div className="container">
                <div className="text-start">
                    <h2 className="text-headingColor font-[700] text-[2.4rem] mb-2">
                        Experience
                    </h2>
                    <div className="rounded-[8px] text-start sm:block bg-headingColor w-full h-2 left-1/2 transform-translate-y-1/2 "></div>
                    {/* <p className="text-headingColor lg:max-w-[600px] lg:mx-auto font-[500] text-[16px] leading-7">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nobis facere odio possimus vitae dolorem, exercitationem beatae eius, corporis nihil facilis dignissimos. Deleniti dicta atque quibusdam omnis. Architecto nesciunt a cum!
                    </p> */}
                </div>
                <Timeline lineColor={'#D5D5D5'}>
                    {companyData.map((company,index)=> (
                        <TimelineItem
                            className='sm:w-full w-[300px]'
                            key = {index}
                            dateText={company.date}
                            style={{ color: company.iconColor }}
                            dateInnerStyle={{ background: company.iconColor, color: '#ddd'}}
                            bodyContainerStyle={{
                                // backgroundImage:`url(${company.imgSrc})`,
                                // filter: 'blur(5px)',
                                // background: '#202020',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 2rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            }}
                            
                        >
                            <div
                                // style={{backgroundImage:`url(${company.imgSrc})`}}
                                className="flex flex-row sm:justify-between flex-wrap sm:flex-nowrap"
                            >
                                <div>
                                    <h3 className="text-[25px] font-[700]">{company.title}</h3>
                                    <h4 className="text-[18px] font-[580]">{company.myPosition}</h4>
                                    {/* <p>
                                        {company.aboutCompany}
                                    </p> */}

                                    <p>
                                        {company.work}
                                    </p>
                                </div>
                                <div className="px-10"
                                    // style={{position:'center'}}
                                >
                                    <img src={company.imgSrc} alt=""  className={company.class}/>
                                </div>
                            </div>
                            
                        </TimelineItem>
                    ))}
                    {/* <TimelineItem
                        key="001"
                        // dateText="11/2010 – Present"
                        style={{ color: '#e86971'}}
                    >
                        <h3>Title, Company</h3>
                        <h4>Subtitle</h4>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                    </TimelineItem>
                    <TimelineItem
                        key="002"
                        dateText="04/2009 – 11/2010"
                        dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
                        bodyContainerStyle={{
                        background: '#ddd',
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        <h3 style={{ color: '#61b8ff' }}>Title, Company</h3>
                        <h4 style={{ color: '#61b8ff' }}>Subtitle</h4>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                    </TimelineItem>
                    <TimelineItem
                        key="003"
                        dateComponent={(
                        <div
                            style={{
                            display: 'block',
                            float: 'left',
                            padding: '10px',
                            background: 'rgb(150, 150, 150)',
                            color: '#fff',
                            }}
                        >
                            11/2008 – 04/2009
                        </div>
                        )}
                    >
                        <h3>Title, Company</h3>
                        <h4>Subtitle</h4>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                    </TimelineItem>
                    <TimelineItem
                        key="004"
                        dateText="08/2008 – 11/2008"
                        dateInnerStyle={{ background: '#76bb7f' }}
                    >
                        <h3>Title, Company</h3>
                        <h4>Subtitle</h4>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                        <p>
                        Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                        exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                        nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                        reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                        est.
                        </p>
                    </TimelineItem> */}
                </Timeline>
                {/* <div className="absolute w-2 sm:block bg-smallTextColor max-h-full h-full transform-translate-y-1/2 m-5 sm:mx-[400px]"></div> */}
            </div>
            
        </section>
    )
}
export default  Experience; 