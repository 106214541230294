// import machineLearning from "/home/ahmed/Desktop/web projects/Portfolios/portfolio/src/assets/images/skillList/machineLearning.webp";
import machineLearning from "../images/skillsList/machineLearning.webp";
import dataVisualization from '../images/skillsList/dataVisualization.webp';
import webDevelopment from '../images/skillsList/webdevelopment.webp';
import artificial_intelligence from '../images/skillsList/artificial-intelligence.webp';
import DataAnalysis from '../images/skillsList/DataAnalysis.webp';
import DatabaseManagement from '../images/skillsList/DatabaseManagement.webp';
import deepLearning from '../images/skillsList/deep-learning.webp';
import Deployment from  '../images/skillsList/Deployment.webp';
import icons from "../../assets/data/iconData";

const skills = [
  {
    id: "01",
    imgUrl: machineLearning,
    category: "ML_DL",
    title: "Machine Learning",
    description:
      "Mastery in creating algorithms that enable computers to learn from and make predictions or decisions based on data.",
    with: ["python"],
    icon: icons.filter(icon => ["Python"].includes(icon.title)).map(icon => icon.img),

    },
    {
        id: "02",
        imgUrl: deepLearning,
        category: "ML_DL",
        title: "Deep Learning",
        description:
          "Proficiency in developing neural networks capable of learning and performing tasks with human-like intelligence, often used in image and speech recognition.",
        with: ["python", "Tableau"],
        icon: icons.filter(icon => ["Python"].includes(icon.title)).map(icon => icon.img),

    },
    {
      id: "03",
      imgUrl: dataVisualization,
      category: "Data Science",
      title: "Data Visualization",
      description:
        "Skillful in presenting data in visual formats such as charts, graphs, and maps to communicate insights effectively.",
      with: ["matplotlib", "Plotly","Seaborn","Tableau"],
      icon: icons.filter(icon => ["Python","Tableau"].includes(icon.title)).map(icon => icon.img),
    },
    {
        id: "04",
        imgUrl: artificial_intelligence,
        category: "AI",
        title: "Artifical Intelligence",
        description:
          "Expertise in developing intelligent systems that mimic human intelligence to perform tasks such as problem-solving, speech recognition, and decision-making.",
        with: ["python", "Tableau"],
        icon: icons.filter(icon => ["Python"].includes(icon.title)).map(icon => icon.img),
      },
    //{
    //     id: "04",
    //     imgUrl: skillImg4,
    //     category: "AI",
    //     title: "LLMs",
    //     description:
    //       "",
    //     with: ["python", "Tableau"],
    //
    //     video:"#",
    // },
    {
        id: "05",
        imgUrl: webDevelopment,
        category: "Web Development",
        title: "Web Development",
        description:
          "Ability to design and create websites using programming languages such as React JS ,Next JS , Laravel, Or with CMS wordpress (Elementor/Divi), ensuring functionality and aesthetics.",
        with: ["python", "Tableau"],
        icon: icons.filter(icon => ["JavaScript", "React JS", "Vue JS","Laravel","Wordpress"].includes(icon.title)).map(icon => icon.img),
      },
    // {
    //     id: "06",
    //     imgUrl: skillImg6,
    //     category: "Web Development",
    //     title: "Portfolio",
    //     description:
    //       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
    //     with: ["python", "Tableau"],
    //
    //     video:"#",
    // },
    {
        id: "06",
        imgUrl: DatabaseManagement,
        category: "Data Science",
        title: "Database Mangement",
        description:
          "Proficient in designing, implementing, and maintaining databases to efficiently store and retrieve data for applications and systems.",
        with: ["python", "SQL"],
        icon: icons.filter(icon => ["Python","MySQL"].includes(icon.title)).map(icon => icon.img),
      },{
        id: "07",
        imgUrl: DataAnalysis,
        category: "Data Science",
        title: "Data Analysis",
        description: "Capability to analyze and interpret data to uncover patterns, trends, and insights that inform decision-making and drive business outcomes.",
        with: ["python", "Mathematics"],
        icon: icons.filter(icon => ["JavaScript", "Python"].includes(icon.title)).map(icon => icon.img),
      },
      {
        id: "09",
        imgUrl: Deployment,
        category: "Data Science",
        title: "Deployment of Data Science Projects",
        description: "Expert in deploying data science projects with Flask, AWS, EC2, and Streamlit. Ensures seamless transition from development to production environments. Empowers organizations with scalable solutions for informed decision-making.",
        with: ["python", "Mathematics"],
        icon: icons.filter(icon => ["flask" ,"aws","ec2","Streamlit"].includes(icon.title)).map(icon => icon.img),
      },
]
export default skills;