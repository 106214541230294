import React,{useState,useEffect} from "react";
import projectData from "../../assets/data/projectData";
import ProjectsList from  '../ItemsList/ProjectsList';

const Projects = () =>{
    const [nextItems,setNextItems] = useState(6)
    const [projects,setProjects] = useState(projectData)
    const [selectTab,setSelectTab] = useState('all')
    const [showModal,setShowModal] = useState(false)
    const [activeID,setActiveID] = useState(null)

    const  showMore=()=> {
        setNextItems(prev=> prev + projects.length)
    }
    const showModalHandler = id =>{
        setShowModal(true)
        setActiveID(id)
        
    }
    useEffect(()=>{
        if(selectTab==='all'){
            setProjects(projectData)
        }
        if(selectTab === 'web-development'){
            setProjects(projectData.filter(item=> item.category=== 'Web Development'))
        }
        if(selectTab === 'Data Science'){
            setProjects(projectData.filter(item=> item.category=== 'Data Science'))
        }
    },[selectTab])
    return <>
    <section id="projects">
        <div className="container">
            <div className="flex items-center justify-between flex-wrap">
                <div className="mb-7 sm:mb-0 w-[50%]">
                    <h3 className="text-headingColor font-bold text-[2rem] mb-2">
                        My Projects
                    </h3>
                </div>
                <div className="flex gap-3">
                    <button onClick={() => setSelectTab('all')} className="text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px] hover:bg-smallTextColor hover:text-white hover:border-white">
                        All
                    </button>
                    <button onClick={() => setSelectTab('web-development')} className="text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px] hover:bg-smallTextColor hover:text-white hover:border-white">
                        Web Development
                    </button>
                    <button onClick={() => setSelectTab('Data Science')} className="text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px] hover:bg-smallTextColor hover:text-white hover:border-white">
                        Data Science
                    </button>
                </div>
                <div className="rounded-[8px] text-start sm:block bg-headingColor w-full h-2 mt-3"></div>
            </div>
            <div className="flex items-center gap-4 flex-wrap mt-12">
                {projects?.slice(0,nextItems)?.map((project,index)=>(
                    <div
                        onClick={() => showModalHandler(project.id)}
                        key={index}
                        data-aos="fade-zoom-in"  
                        data-aos-delay="50"
                        data-aos-duration="1000" 
                        className="group max-w-full sm:w-[48.5%] md:w-[31.8%] lg:w-[32.2%] relative z-1">
                        <figure>
                            <img  className="rounded-[8px] w-[364px] h-[243px]"  src={project.imgUrl} alt="" />
                        </figure>
                        <div className="w-full h-full bg-primaryColor bg-opacity-40 absolute top-0 left-0 z-[5] hidden group-hover:block">
                        
                            <div className="w-full h-full flex items-center justify-center">
                                <button onClick={() => showModalHandler(project.id)} className="text-white bg-headingColor hover:bg-primaryColor py-2 px-4 rounded-[8px] ease-in duration-200 font-[500]">
                                    See Details
                                </button>
                            </div>

                        </div>
                    </div>
                ))}
            </div>
            <div className="text-center mt-6">
                {
                    nextItems < projects.length && projectData.length > 6 && (
                        <button 
                                onClick={showMore}
                                className="hover:text-white hover:bg-headingColor py-2 px-4 rounded-[8px] bg-primaryColor text-white font-[500] ease-in duration-200">
                                Load More
                        </button>
                    )
                }
                
            </div>
        </div>
            {
                showModal && <ProjectsList setShowModal={setShowModal} activeID={activeID}/>
            }
    </section>
    </>
        
}
export default Projects;