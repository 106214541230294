import businessGame from "../images/projectsList/bg.webp";
import portfolio from "../images/projectsList/portfolio.png";
import bankAccountPrediction from "../images/projectsList/bankAccountPrediction.png";
import DigitalTechStore from "../images/projectsList/DigitalTechStore.png";
import projectImg04 from "../images/projectsList/project-04.jpg";
import repartidis from "../images/projectsList/Repartidis.png";
import projectImg06 from "../images/projectsList/project-06.jpg";
import projectImg07 from "../images/projectsList/project-07.jpg";

const projects = [
  {
    id: "05",
    imgUrl: businessGame,
    category: "Web Development",
    title: "businessGame Game Software",
    description:
      "Create a software that simulates a competitive market, where teams representing companies compete in producing and selling various products for the third iteration of the IEC Business Game event.",
    technologies: ["Vue JS", "Tailwind css","Laravel"],
    siteUrl: "#",
    githubUrl:"#",
  },
  {
    id: "02",
    imgUrl: portfolio,
    category: "Web Development",
    title: "My Portfolio Website",
    description:
      "This project entails the creation of a comprehensive portfolio to showcase professional achievements, skills, and experiences. The portfolio serves as a centralized platform to present past projects, educational background, certifications, and any other relevant information to prospective employers or clients.",
    technologies: ["React", "Tailwind css", "Next.js"],
    siteUrl: "https://abdelnour.digitaltechstore.net/index.html",
    githubUrl:"#",
  },
  {
    id: "03",
    imgUrl: DigitalTechStore,
    category: "Web Development",
    title: "E-commerce Website (Digital Tech Store)",
    description:
      "This project involves the creation of a dynamic e-commerce platform tailored specifically for a Digital Tech Store, offering a diverse range of digital products and services. The website serves as a virtual storefront to showcase products, facilitate transactions, and provide a seamless shopping experience for customers.",
    technologies: ["Wordpress", "Woocommerce", "Elementor"],
    siteUrl: "https://digitaltechstore.net/",
    githubUrl:"#",
  },
  {
    id: "04",
    imgUrl: repartidis,
    category: "Web Development",
    title: "Landing Page for a Real Estate Company (REPARTIDIS)",
    description:
      "This project entails the creation of a representative website for SARL Repartidis, designed to showcase the company's services, expertise, and values to potential clients and stakeholders. The website serves as a digital storefront, providing essential information about SARL Repartidis and its offerings while reflecting the company's professional image and brand identity.",
    technologies: ["Wordpress", "Woocommerce", "Divi"],
    siteUrl: "https://repartidis-dz.com/",
    githubUrl:"#",
  },
  {
    id: "01",
    imgUrl: bankAccountPrediction,
    category: "Data Science",
    title: "Bank Account Prediction",
    description:
      "This project involves developing a machine learning model to predict whether an individual is likely to open a bank account based on various demographic and socio-economic factors. The model will be trained using a dataset containing historical information about individuals' characteristics and their banking behavior. Additionally, the project includes deploying the trained model on Streamlit Share, a platform for sharing data apps created with Streamlit, to allow users to interactively explore the predictions.",
    technologies: ["Python", "Sklearn", "pandas", "Streamlit"],
    siteUrl: "https://bankaccountprediction-6usknxfsucrmkycpeksijp.streamlit.app/",
    githubUrl:"https://github.com/terfaniahmedabdelnour/bank_account_prediction/tree/main",
  },

  // {
  //   id: "06",
  //   imgUrl: projectImg06,
  //   category: "Ux",
  //   title: "Online Therapy Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  //   githubUrl:"#",
  // },
  // {
  //   id: "07",
  //   imgUrl: projectImg07,
  //   category: "Web Development",
  //   title: "Appointment Booking Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  //   githubUrl:"#",
  // },
  // {
  //   id: "08",
  //   imgUrl: businessGame,
  //   category: "Web Development",
  //   title: "Finance Technology Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  //   githubUrl:"#",
  // },
  // {
  //   id: "09",
  //   imgUrl: DigitalTechStore,
  //   category: "Ux",
  //   title: "githubUrl Conference Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  //   githubUrl:"#",
  // },
  // {
  //   id: "10",
  //   imgUrl: bankAccountPrediction,
  //   category: "Ux",
  //   title: "File Sharing Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  //   githubUrl:"#",
  // },
  // {
  //   id: "11",
  //   imgUrl: projectImg04,
  //   category: "Web Development",
  //   title: "Landing Page",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  //   githubUrl:"#",
  // },
  // {
  //   id: "12",
  //   imgUrl: repartidis,
  //   category: "Web Development",
  //   title: "Landing Page",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  //   githubUrl:"#",
  // },
  // {
  //   id: "13",
  //   imgUrl: projectImg06,
  //   category: "Web Development",
  //   title: "Online Therapy Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  //   githubUrl:"#",
  // },
  // {
  //   id: "14",
  //   imgUrl: projectImg07,
  //   category: "ux",
  //   title: "Appointment Booking Website",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
  //   technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  //   siteUrl: "#",
  //   githubUrl:"#",
  // },
];

export default projects;