import erricson from '../images/companies/ericsson.webp';
import agence19 from '../images/companies/agence19.png';
import yassir from '../images/companies/yassir.webp';
import iec from '../images/companies/iec.png';

const  companies = [
    {
        id: "1",
        title: "Ericsson",
        imgSrc: erricson,
        work: ['Under working'],
        myPosition: ["Data Scientist Intern"],
        date: "12/03/2024 - Present",
        iconColor: '#011D9B',
        class:"sm:w-[100px] w-[50px] pt-[10px]",        
    },
    {
        id: "2",
        title: "Agence19",
        imgSrc: agence19,
        work: ['I work on building websites with wordpress (Elementor,Divi) and with HTML/CSS/JS for the frontend and PHP/MySQL for the backend.'],
        myPosition: ["front-end web developer"],
        date: "01/2023 - 08/2023",
        iconColor: '#61b8ff',
        class:"sm:w-[500px] sm:pt-[50px] pt-[5px]",

    },{
        id: "3",
        title: "YASSIR",
        imgSrc: yassir,
        work: ['In yassir intership i had the chance to visit every signle department : (Crea,IT,Product,Marketing,Data Science..)'],
        myPosition: ["Discovery intership"],
        date: "12/2022 - 01/2023",
        iconColor: '#FB2477',
        class:"sm:w-[400px] sm:pt-[50px] pt-[10px]",

    },{
        id: "4",
        title: "Industrial Engineers Club",
        imgSrc: iec,
        work: ['In IEC i worked on the part of front-end with Laravel/VueJS and tailwind css for the development of a software named "Bussiness Game" that simulates a competitive market'],
        myPosition: ["front-end web Developer"],
        date: "03/2023 - 05/2023",
        iconColor: '#132131',
        class:"w-[500px] sm:pt-[50px]",

    },
    // {
    //     id: "1",
    //     title: "Ericsson",
    //     imgSrc: erricson,
    //     work: 'Ericsson is a global technology company that provides mobile communications and networking',
    //     myPosition: ["Developed a mobile application for Ericsson's internal use.", "Worked on the development of an IoT platform using React.JS"],
    //     date: "2018 - Present",
    //     iconColor: '#e86971',

    // },
    
]
export default companies;

