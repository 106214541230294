import js from "../images/icons/js.webp";
import react from "../images/icons/react.webp";
import c from "../images/icons/C.webp";
import cpp from "../images/icons/C++.webp";
import larvel from "../images/icons/laravel.webp";
import python from "../images/icons/python.webp";
import php from "../images/icons/php.webp";
import vue from "../images/icons/vuejs.webp";
import pandas from "../images/icons/pandas.webp";
import numpy from "../images/icons/numpy.webp";
import sklearn from "../images/icons/sklearn.webp";
import streamlit from "../images/icons/streamlit.webp";
import sql from "../images/icons/sql.webp";
import tensorflow from "../images/icons/tensorflow.webp";
import keras from "../images/icons/keras.webp";
import tableau from "../images/icons/tableau.webp";
import matplotlib from "../images/icons/matplotlib.webp";
import seaborn from "../images/icons/seaborn.webp";
import wordpress from "../images/icons/wordpress.webp";
import elementor from "../images/icons/elementor.webp";
import divi from "../images/icons/divi.webp";
import flask  from "../images/icons/flask.webp";
import aws from "../images/icons/aws.webp";
import ec2 from "../images/icons/ec2.webp";

const icons = [
    {
        id: '1',
        img:js,
        title:'JavaScript',
        alt:'JavaScript',
    },{
        id: '2',
        img:react,
        title:'React JS',
        alt:'React JS',
    },{
        id: '3',
        img:cpp,
        title:'C++',
        alt:'C++',
    },{
        id: '4',
        img:c,
        title:'C',
        alt:'C',
    },
    {
        id: '5',
        img:larvel,
        title:'Laravel',
        alt:'Laravel',
    },{
        id: '6',
        img:php,
        title:'PHP',
        alt:'PHP',
    },{
        id: '7',
        img:python,
        title:'Python',
        alt:'Python',
    },{
        id: '8',
        img:vue,
        title:'Vue JS',
        alt:'Vue JS',
    },
    {
        id: '9',
        img:streamlit,
        title:'Streamlit',
        alt:'Streamlit',
    },
    {
        id: '10',
        img:pandas,
        title:'Pandas',
        alt:'Pandas',
    },
    {
        id: '11',
        img:numpy,
        title:'Numpy',
        alt:'Numpy',
    },
    {
        id: '12',
        img:sklearn,
        title:'Sklearn',
        alt:'Sklearn',
    },
    {
        id: '13',
        img:sql,
        title:'MySQL',
        alt:'MySQL',
    },
    {
        id: '14',
        img:tensorflow,
        title:'Tensorflow',
        alt:'Tensorflow',
    },
    {
        id: '15',
        img:keras,
        title:'Keras',
        alt:'Keras',
    },
    {
        id: '16',
        img:tableau,
        title:'Tableau',
        alt:'Tableau',
    },{
        id: '17',
        img:matplotlib,
        title:'Matplotlib',
        alt:'Matplotlib',
    },{
        id: '18',
        img:seaborn,
        title:'Seaborn',
        alt:'Seaborn',
    },
    {
        id: '19',
        img:wordpress,
        title:'Wordpress',
        alt:'Wordpress',
    },
    {
        id: '20',
        img:elementor,
        title:'Elementor',
        alt:'Elementor',
    },
    {
        id: '21',
        img:divi,
        title:'Divi',
        alt:'Divi',
    },{
        id: '22',
        img:aws,
        title:'aws',
        alt:'aws',
    },{
        id: '23',
        img:ec2,
        title:'ec2',
        alt:'ec2',
    },{
        id: '24',
        img:flask,
        title:'flask',
        alt:'flask',
    },
]

export default icons;