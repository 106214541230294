import React from "react";

function  Footer() {
    const year = new  Date().getFullYear();
    return (
        <footer className="bg-[#12141e]">
            
            {/* ========= Start Footer top ========= */}
            <div className="container py-[60px]">
                <div className="sm:flex items-center justify-between md:gap-8">
                    <div className="w-full sm:w-1/2">
                        <h2 className="text-[26px] leading-10 text-white font-[600] mb-5 md:text-[2rem]">
                            Let's collaborate to turn ideas into beautiful, functional <span> realities!</span>
                        </h2>
                        <a href="#contact">
                            <button className="bg-primaryColor text-white font-[500] flex items-center gap-2 hover:bg-smallTextColor ease-in duration-300 py-2 px-4 rounded-[8px] mb-5">
                                <i class="ri-mail-line"></i>Hire me 
                            </button>
                        </a>
                    </div>
                    <div className="w-full sm:w-1/2">
                        <p className="text-gray-300 font-[600] text-[15px]">
                            Thrilled to embark on a data-driven journey! Leveraging my expertise in Data Science and AI, I'll be crafting innovative solutions. Stay tuned as I blend technology and creativity in web development.
                        </p>
                        <div className="flex items-center gap-4 flex-wrap md:gap-8 mt-10">
                            <span className="text-gray-300 font-[600] text-15px]">
                                Follow Me :
                            </span>
                            
                            <span className="w-[35px] h-[35px] bg-[#2b2d33] hover:bg-primaryColor p-1 rounded-[50px] cursor-pointer text-center">
                                <a  target="_blank"
                                    className="text-white font-[500] text-[18px]"
                                    href="https://www.linkedin.com/in/ahmed-abdennour-terfani-1b22a7251/"
                                >
                                    <i class="ri-linkedin-fill"></i>  
                                </a>
                            </span>
                            <span className="w-[35px] h-[35px] bg-[#2b2d33] hover:bg-primaryColor p-1 rounded-[50px] cursor-pointer text-center">
                                <a  className="text-white font-[500] text-[18px]"
                                    href="https://github.com/terfaniahmedabdelnour"
                                    target="_blank"
                                >
                                    <i class="ri-github-line"></i>  
                                </a>
                            </span>
                            <span className="w-[35px] h-[35px] bg-[#2b2d33] hover:bg-primaryColor p-1 rounded-[50px] cursor-pointer text-center">
                                <a  className="text-white font-[500] text-[18px]"
                                    href="https://discordapp.com/users/abdennour#2938"
                                    target="_blank"
                                >
                                    <i class="ri-discord-fill"></i> 
                                </a>
                            </span>
                            {/* <span className="w-[35px] h-[35px] bg-[#626469] p-1 rounded-[50px] cursor-pointer text-center">
                                <a  className="text-gray-300 font-[500] text-[18px]"
                                    href="https://github.com/terfaniahmedabdelnour"
                                >
                                    <i class="ri-github-line"></i>  
                                </a>
                            </span> */}
                        </div>
                    </div>
                </div>

                <div>
                        <ul className="flex items-center justify-center gap-10 mt-10 flex-wrap">
                            <li>
                                <a className = "text-gray-200 font-[600] hover:text-primaryColor" href="#about">About</a>
                            </li>
                            <li>
                                <a className = "text-gray-200 font-[600] hover:text-primaryColor" href="#experience">Experience</a>
                            </li>
                            <li>
                                <a className = "text-gray-200 font-[600] hover:text-primaryColor" href="#education">Education</a>
                            </li>
                            <li>
                                <a className = "text-gray-200 font-[600] hover:text-primaryColor" href="#skills">Skills</a>
                            </li>
                            <li>
                                <a className = "text-gray-200 font-[600] hover:text-primaryColor" href="#projects">Projects</a>
                            </li>
                            
                            <li>
                                <a className = "text-gray-200 font-[600] hover:text-primaryColor" href="#contacts">Contact</a>
                            </li>
                        </ul>
                    </div>
            </div>
            {/* ========= End Footer top ========= */}
            {/* ========= Start Footer buttom ========= */}
            <div className="bg-[#1b1e29] py-10">
                <div className="container">
                    <div className="flex items-center justify-center sm:justify-between">
                        <div className="hidden sm:block">
                            <div className="flex items-center gap-[10px]">
                                <span className="w-[35px] h-[35px] rounded-full bg-[#2b2d33] text-white font-[500] text-[18px] flex items-center justify-center">
                                    <a href="#">A</a>
                                </span>
                                <div className="leading-[20px]">
                                    <h2 className="text-gray-200 font-[500] text-[18px]">
                                        <a href="#">Abdelnour</a>
                                    </h2>
                                    <p className="text-gray-400 text-[14px] font-[500]">
                                        Personal
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p
                                className="text-gray-200 text-[14px]">
                                Copyright © {year} by Abdelnour All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* ========= End Footer buttom ========= */}
        </footer>
    )
}
export default Footer;