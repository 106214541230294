import axios from "axios";
import React, { useState  , useRef} from "react";
import { toast , ToastContainer} from "react-toastify";
import emailjs from '@emailjs/browser';


const Contact =()=>{
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ch36wai', 'template_du6m3f1', form.current, {
        publicKey: 'cMt17w5FyUIIelI1m',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
    return(
        <section id="contact" className="pb-16">
            <div className="container">
                <h2 className="text-smallTextColor text-[2.5rem] font-[600] mb-8">
                    Get  in touch !
                </h2>
                <div className="md:flex justify-between items-center">
                    <div className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
                        <iframe title="google maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3197.9741161596944!2d3.1481921763457104!3d36.72318287203644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128e52692905dcdf%3A0x2bb008bbddc747d3!2sEcole%20Nationale%20Polytechnique!5e0!3m2!1sfr!2sdz!4v1709647740790!5m2!1sfr!2sdz" width="600" height="450" className="border-0 w-full h-full" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>

                    <div className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center bg-indigo-100 px-4 lg:px-8 py-8">
                        <ToastContainer position="bottom-right" limit={1} />
                        <form className="w-full" action="mailto:terfaniahmedabdelnour@g.enp.edu.dz" method="get" enctype="text/plain" ref={form} onSubmit={sendEmail} >
                            <div className="mb-5"> 
                                <label htmlFor="from_name"></label>
                                <input
                                    // onChange={(e)=> setName(e.target.value)}
                                    id="from_name"
                                    type="text"
                                    placeholder="Enter your Name"
                                    className="w-full p-3 focus:outline-none rounded-[5px]"
                                    name="from_name"
                                />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="from_email"></label>
                                <input
                                    // onChange={(e)=> setEmail(e.target.value)}
                                    id="from_email"
                                    type="email"
                                    placeholder="Enter your Email"
                                    className="w-full p-3 focus:outline-none rounded-[5px]"
                                    name="from_email"
                                />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="subject"></label>
                                <input
                                    // onChange={(e)=> setSubject(e.target.value)}
                                    id="subject"
                                    type="text"
                                    placeholder="Subject"
                                    className="w-full p-3 focus:outline-none rounded-[5px]"
                                    name="subject"
                                />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="message"></label>
                                <textarea
                                    // onChange={(e)=> setMessage(e.target.value)}
                                    id="message"
                                    type="text"
                                    rows={3}
                                    placeholder="Write yout message "
                                    className="w-full p-3 focus:outline-none rounded-[5px]"
                                    name="message"
                                />
                            </div>
                            <button 
                                className="w-full p-3 focus:outline-none rounded-[5px] bg-smallTextColor text-white hover:bg-primaryColor hover:text-white text-center ease-linear duration-150"
                            >
                                Send Message
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Contact;